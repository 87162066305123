import * as React from 'react';
import classNames from 'classnames';
function renderExpandIcon(locale) {
    return function expandIcon({ prefixCls, onExpand, record, expanded, expandable, }) {
        const iconPrefix = `${prefixCls}-row-expand-icon`;
        return (React.createElement("button", { type: "button", onClick: (e) => {
                onExpand(record, e);
                e.stopPropagation();
            }, className: classNames(iconPrefix, {
                [`${iconPrefix}-spaced`]: !expandable,
                [`${iconPrefix}-expanded`]: expandable && expanded,
                [`${iconPrefix}-collapsed`]: expandable && !expanded,
            }), "aria-label": expanded ? locale.collapse : locale.expand, "aria-expanded": expanded }));
    };
}
export default renderExpandIcon;
