import React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import pickAttrs from 'rc-util/lib/pickAttrs';
export function pickClosable(context) {
    if (!context) {
        return undefined;
    }
    return {
        closable: context.closable,
        closeIcon: context.closeIcon,
    };
}
/** Convert `closable` and `closeIcon` to config object */
function useClosableConfig(closableCollection) {
    const { closable, closeIcon } = closableCollection || {};
    return React.useMemo(() => {
        if (
        // If `closable`, whatever rest be should be true
        !closable &&
            (closable === false || closeIcon === false || closeIcon === null)) {
            return false;
        }
        if (closable === undefined && closeIcon === undefined) {
            return null;
        }
        let closableConfig = {
            closeIcon: typeof closeIcon !== 'boolean' && closeIcon !== null ? closeIcon : undefined,
        };
        if (closable && typeof closable === 'object') {
            closableConfig = Object.assign(Object.assign({}, closableConfig), closable);
        }
        return closableConfig;
    }, [closable, closeIcon]);
}
/**
 * Assign object without `undefined` field. Will skip if is `false`.
 * This helps to handle both closableConfig or false
 */
function assignWithoutUndefined(...objList) {
    const target = {};
    objList.forEach((obj) => {
        if (obj) {
            Object.keys(obj).forEach((key) => {
                if (obj[key] !== undefined) {
                    target[key] = obj[key];
                }
            });
        }
    });
    return target;
}
/** Use same object to support `useMemo` optimization */
const EmptyFallbackCloseCollection = {};
export default function useClosable(propCloseCollection, contextCloseCollection, fallbackCloseCollection = EmptyFallbackCloseCollection) {
    // Align the `props`, `context` `fallback` to config object first
    const propCloseConfig = useClosableConfig(propCloseCollection);
    const contextCloseConfig = useClosableConfig(contextCloseCollection);
    const mergedFallbackCloseCollection = React.useMemo(() => (Object.assign({ closeIcon: React.createElement(CloseOutlined, null) }, fallbackCloseCollection)), [fallbackCloseCollection]);
    // Use fallback logic to fill the config
    const mergedClosableConfig = React.useMemo(() => {
        // ================ Props First ================
        // Skip if prop is disabled
        if (propCloseConfig === false) {
            return false;
        }
        if (propCloseConfig) {
            return assignWithoutUndefined(mergedFallbackCloseCollection, contextCloseConfig, propCloseConfig);
        }
        // =============== Context Second ==============
        // Skip if context is disabled
        if (contextCloseConfig === false) {
            return false;
        }
        if (contextCloseConfig) {
            return assignWithoutUndefined(mergedFallbackCloseCollection, contextCloseConfig);
        }
        // ============= Fallback Default ==============
        return !mergedFallbackCloseCollection.closable ? false : mergedFallbackCloseCollection;
    }, [propCloseConfig, contextCloseConfig, mergedFallbackCloseCollection]);
    // Calculate the final closeIcon
    return React.useMemo(() => {
        if (mergedClosableConfig === false) {
            return [false, null];
        }
        const { closeIconRender } = mergedFallbackCloseCollection;
        const { closeIcon } = mergedClosableConfig;
        let mergedCloseIcon = closeIcon;
        if (mergedCloseIcon !== null && mergedCloseIcon !== undefined) {
            // Wrap the closeIcon if needed
            if (closeIconRender) {
                mergedCloseIcon = closeIconRender(closeIcon);
            }
            // Wrap the closeIcon with aria props
            const ariaProps = pickAttrs(mergedClosableConfig, true);
            if (Object.keys(ariaProps).length) {
                mergedCloseIcon = React.isValidElement(mergedCloseIcon) ? (React.cloneElement(mergedCloseIcon, ariaProps)) : (React.createElement("span", Object.assign({}, ariaProps), mergedCloseIcon));
            }
        }
        return [true, mergedCloseIcon];
    }, [mergedClosableConfig, mergedFallbackCloseCollection]);
}
