import * as React from 'react';
export const defaultIconPrefixCls = 'anticon';
export const Variants = ['outlined', 'borderless', 'filled'];
const defaultGetPrefixCls = (suffixCls, customizePrefixCls) => {
    if (customizePrefixCls) {
        return customizePrefixCls;
    }
    return suffixCls ? `ant-${suffixCls}` : 'ant';
};
// zombieJ: 🚨 Do not pass `defaultRenderEmpty` here since it will cause circular dependency.
export const ConfigContext = React.createContext({
    // We provide a default function for Context without provider
    getPrefixCls: defaultGetPrefixCls,
    iconPrefixCls: defaultIconPrefixCls,
});
export const { Consumer: ConfigConsumer } = ConfigContext;
