export function toList(val) {
    if (val === false) {
        return [false, false];
    }
    return Array.isArray(val) ? val : [val];
}
export function getNode(dom, defaultNode, needDom) {
    if (dom === true || dom === undefined) {
        return defaultNode;
    }
    return dom || (needDom && defaultNode);
}
