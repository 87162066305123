import { Color as RcColor } from '@rc-component/color-picker';
export const toHexFormat = (value, alpha) => (value === null || value === void 0 ? void 0 : value.replace(/[^\w/]/gi, '').slice(0, alpha ? 8 : 6)) || '';
export const getHex = (value, alpha) => (value ? toHexFormat(value, alpha) : '');
export class ColorFactory {
    constructor(color) {
        this.cleared = false;
        this.metaColor = new RcColor(color);
        if (!color) {
            this.metaColor.setAlpha(0);
            this.cleared = true;
        }
    }
    toHsb() {
        return this.metaColor.toHsb();
    }
    toHsbString() {
        return this.metaColor.toHsbString();
    }
    toHex() {
        return getHex(this.toHexString(), this.metaColor.getAlpha() < 1);
    }
    toHexString() {
        return this.metaColor.getAlpha() === 1
            ? this.metaColor.toHexString()
            : this.metaColor.toHex8String();
    }
    toRgb() {
        return this.metaColor.toRgb();
    }
    toRgbString() {
        return this.metaColor.toRgbString();
    }
}
